import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaChartBar, FaGamepad, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
const API = process.env.REACT_APP_API_URL;

const AdminDashboard = () => {
  const [selectedMenu, setSelectedMenu] = useState('adminGames');
  const [adminGames, setAdminGames] = useState([]);
  const [dailyReports, setDailyReports] = useState([]);

  useEffect(() => {
    fetchAdminGames();
    fetchDailyReports();
  }, []);

  const fetchAdminGames = async () => {
    try {
      const token = localStorage.getItem('spinbonustoken');
      const response = await axios.get(`${API}/api/admin/games`, {
        headers: {
          Authorization: token,
        },
      });
      setAdminGames(response.data.games);
    } catch (error) {
      console.error('Error fetching admin games:', error);
    }
  };

  const fetchDailyReports = async () => {
    try {
      const token = localStorage.getItem('spinbonustoken');
      const response = await axios.get(`${API}/api/admin/reports`, {
        headers: {
          Authorization: token,
        },
      });
      setDailyReports(response.data.reports);
    } catch (error) {
      console.error('Error fetching daily reports:', error);
    }
  };

  const renderAdminGames = () => {
    return (
      <div className="data-table">
        <h2 className="section-title">Admin Games</h2>
        <table>
          <thead>
            <tr>
              <th>Created At</th>
              <th>Prize Amount</th>
              <th>Winner</th>
              <th>Number of Players</th>
            </tr>
          </thead>
          <tbody>
            {adminGames.map((game) => {
              const formattedDate = new Date(game.createdAt).toLocaleString('en-US', {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
                hour12: false,
              });
              return (
                <tr key={game._id}>
                  <td>{formattedDate}</td>
                  <td>{game.prizeAmount} ብር</td>
                  <td>{game.winner}</td>
                  <td>{game.numbers.length}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    );
  };

  const renderDailyReports = () => {
    return (
      <div className="data-table">
        <h2 className="section-title">Daily Reports</h2>
        <table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Total Payout</th>
              <th>Total Games</th>
            </tr>
          </thead>
          <tbody>
          {dailyReports.map((report) => {
            const formattedDate = new Date(report.date).toLocaleString('en-US', {
              weekday: 'long',
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            });
            return (
              <tr key={report.date}>
                <td>{formattedDate}</td>
                <td>{report.totalPayout} ብር</td>
                <td>{report.totalGames}</td>
              </tr>
            );
          })}
        </tbody>
        </table>
      </div>
    );
  };

  return (
    <div className="admin-dashboard" style={{
      background: 'linear-gradient(45deg, #000000, #1a1a1a)',
      minHeight: '100vh',
      color: '#FFD700',
      fontFamily: '"Times New Roman", serif',
      padding: '20px',
    }}>
      <h1 style={{
        textAlign: 'center',
        fontSize: '3em',
        marginBottom: '30px',
        textShadow: '2px 2px 4px rgba(255,215,0,0.5)',
      }}>Admin Dashboard</h1>
      <div className="menu-buttons" style={{
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '30px',
      }}>
        <button
          className={`menu-button ${selectedMenu === 'adminGames' ? 'active' : ''}`}
          onClick={() => setSelectedMenu('adminGames')}
          style={{
            background: selectedMenu === 'adminGames' ? '#FFD700' : '#000',
            color: selectedMenu === 'adminGames' ? '#000' : '#FFD700',
            border: '2px solid #FFD700',
            padding: '10px 20px',
            margin: '0 10px',
            fontSize: '1.2em',
            cursor: 'pointer',
            transition: 'all 0.3s ease',
            borderRadius: '25px',
          }}
        >
          <FaGamepad style={{ marginRight: '10px' }} /> Admin Games
        </button>
        <button
          className={`menu-button ${selectedMenu === 'reports' ? 'active' : ''}`}
          onClick={() => setSelectedMenu('reports')}
          style={{
            background: selectedMenu === 'reports' ? '#FFD700' : '#000',
            color: selectedMenu === 'reports' ? '#000' : '#FFD700',
            border: '2px solid #FFD700',
            padding: '10px 20px',
            margin: '0 10px',
            fontSize: '1.2em',
            cursor: 'pointer',
            transition: 'all 0.3s ease',
            borderRadius: '25px',
          }}
        >
          <FaChartBar style={{ marginRight: '10px' }} /> Reports
        </button>
      </div>
      <div className="data-container" style={{
        background: 'rgba(0,0,0,0.5)',
        borderRadius: '15px',
        padding: '20px',
        boxShadow: '0 0 20px rgba(255,215,0,0.3)',
      }}>
        {selectedMenu === 'adminGames' && renderAdminGames()}
        {selectedMenu === 'reports' && renderDailyReports()}
      </div>
      <style jsx>{`
        .data-table {
          overflow-x: auto;
        }
        table {
          width: 100%;
          border-collapse: separate;
          border-spacing: 0 10px;
        }
        th, td {
          padding: 15px;
          text-align: left;
          border-bottom: 1px solid #FFD700;
        }
        th {
          background-color: #FFD700;
          color: #000;
          font-weight: bold;
        }
        tr:hover {
          background-color: rgba(255,215,0,0.1);
        }
        .section-title {
          color: #FFD700;
          font-size: 2em;
          margin-bottom: 20px;
          text-align: center;
        }
        @media (max-width: 768px) {
          .menu-buttons {
            flex-direction: column;
          }
          .menu-button {
            margin: 10px 0;
          }
        }
      `}</style>
    </div>
  );
};

export default AdminDashboard;