import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import './App.css';
import SpinWheel from './Wheel';
import WinnerPopup from './WinnerPopup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter as Router, Route, Link, Routes, useLocation } from 'react-router-dom';
import AdminDashboard from './AdminDashboard';
import { FaDice, FaCoins } from 'react-icons/fa';
import LuxuryLogin from './LuxuryLogin';
const API = process.env.REACT_APP_API_URL;

function useQuery() {
  const { search } = window.location;
  return React.useMemo(() => new URLSearchParams(search), [search]);
}
function App() {
  const query = useQuery();
  const [selectedNumbers, setSelectedNumbers] = useState([]);
  const [winner, setWinner] = useState(null);
  const [isSpinning, setIsSpinning] = useState(false);
  const [bettingAmount, setBettingAmount] = useState(parseInt(localStorage.getItem('bettingAmount')) || 0);
  const [spinSpeed, setSpinSpeed] = useState(parseFloat(localStorage.getItem('spinSpeed')) || 7);
  const [showPopup, setShowPopup] = useState(false);
  const [showWheel, setShowWheel] = useState(false);
  const [loggedInUser, setLoggedInUser] = useState(null);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [buttonClicked, setButtonClicked] = useState(false);
  const [prizeAmount, setPrizeAmount] = useState(null);

  useEffect(() => {
    const token = query.get('token') || localStorage.getItem('spinbonustoken');
    const numbersFromURL = query.get('selectedNumbers');
    
    if (token) {
      localStorage.setItem('spinbonustoken', token);
      setLoggedInUser(true);
    }

    if (numbersFromURL) {
      const parsedNumbers = JSON.parse(numbersFromURL);
      setSelectedNumbers(parsedNumbers);
    }
  }, [query]);

  const handleNumberClick = (number) => {
    if (selectedNumbers.includes(number)) {
      setSelectedNumbers(selectedNumbers.filter((num) => num !== number));
    } else {
      setSelectedNumbers([...selectedNumbers, number]);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('spinbonustoken');
    if (token) {
      setLoggedInUser(true);
    }
  }, []);

  const handleLogin = async (username, password) => {
    try {
      const response = await axios.post(`${API}/api/login`, { username, password });
      const { token } = response.data;
      localStorage.setItem('spinbonustoken', token);
      setLoggedInUser(true);
      toast.success('Login Successful');
    } catch (error) {
      console.error('Login failed:', error);
      toast.error('Login failed. Please check your credentials.');
    }
  };

  const handleCreateGame = async () => {
    try {
      setButtonClicked(true);
      const token = localStorage.getItem('spinbonustoken');
      const response = await axios.post(`${API}/api/game`, {
        numbers: selectedNumbers,
      }, {
        headers: { Authorization: `Bearer ${token}`},
      });
      setShowWheel(true);
      setIsSpinning(true);
      setWinner(response.data.winner);
      setPrizeAmount(response.data.prizeAmount);
    } catch (error) {
      console.error('Game creation failed:', error);
      toast.error('Game creation failed. Please try again.');
    }
  };

  const handleStopSpinning = () => {
    setTimeout(() => {
      setIsSpinning(false);
      setTimeout(() => {
        setShowPopup(true);
      }, 500);
    }, 1000); // Delay of 1 second before hiding the wheel
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    window.location.href = 'https://powerbingo.shop/dashboard';
  };

  const handleBettingAmountChange = (e) => {
    const amount = parseInt(e.target.value);
    setBettingAmount(amount);
    localStorage.setItem('bettingAmount', amount);
  };
  
  const handleSpinSpeedChange = (e) => {
    const speed = parseFloat(e.target.value);
    setSpinSpeed(speed);
    localStorage.setItem('spinSpeed', speed);
  };

  const renderNumberGrid = () => {
    const numbers = [];
    for (let i = 1; i <= 100; i++) {
      numbers.push(
        <div
          key={i}
          className={`number ${isSpinning ? 'd-none' : ''} fw-bold ${selectedNumbers.includes(i) ? 'selected' : ''}`}
          // onClick={() => handleNumberClick(i)}
          style={{
            backgroundColor: selectedNumbers.includes(i) ? '#ffd700' : 'rgba(0, 0, 0, 0.7)',
            color: selectedNumbers.includes(i) ? '#000' : '#ffd700',
            border: '1px solid #ffd700',
            borderRadius: '15px',
            cursor: 'pointer',
            transition: 'all 0.3s ease',
            minHeight: '50px',
            marginBottom: '10px',
            boxShadow: selectedNumbers.includes(i) ? '0 0 10px #ffd700' : 'none'
          }}
        >
          {i}
        </div>
      );
    }
    return <div className="number-grid">{numbers}</div>;
  };

  return (
    <Router>
      <div className="luxury-casino-bg text-white p-4" style={{
        minHeight: '100vh',
        backgroundColor: '#1a1a1a',
        backgroundImage: 'url("https://example.com/luxury-pattern.png")', // Replace with actual luxury pattern image
        backgroundBlendMode: 'overlay',
        color: '#ffd700'
      }}>
        <Link to="/admin" className="text-gold" style={{ fontSize: '20px', marginRight: '50%', marginTop: '0', color: '#ffd700', textDecoration: 'none' }}> <FaDice style={{ marginLeft: '15px' }} />   Power Casino Admin</Link>
        <Routes>
          <Route
            exact
            path="/"
            element={
              <>
                <div className="text-center">
                  <h1 style={{ 
                    color: '#ffd700', 
                    textShadow: '2px 2px 4px rgba(0,0,0,0.5)',
                    fontFamily: '"Playfair Display", serif',
                    fontSize: '3.5rem'
                  }}>
                    <FaDice style={{ marginRight: '15px' }} />
                    Power Bonus Jackpot
                    <FaDice style={{ marginLeft: '15px' }} />
                  </h1>
                </div>
                {!loggedInUser ? (
                  <LuxuryLogin onLogin={handleLogin} />
                ) : (
                  <>
                    <div className='m-5'>{renderNumberGrid()}</div>
                    <div className="text-center mt-4">
                      <Button
                        onClick={handleCreateGame}
                        disabled={selectedNumbers.length === 0 || buttonClicked}
                        className={`${isSpinning ? 'd-none' : ''} hover-effect`}
                        style={{
                          backgroundColor: '#ffd700',
                          color: '#000',
                          border: 'none',
                          borderRadius: '25px',
                          padding: '15px 40px',
                          fontSize: '1.2rem',
                          fontWeight: 'bold',
                          transition: 'all 0.3s ease'
                        }}
                      >
                        {isSpinning ? 'Spinning...' : 'Lucky Draw'}
                      </Button>
                    </div>
                    {showWheel && !showPopup && (
                      <SpinWheel
                        numbers={selectedNumbers}
                        winner={winner}
                        prizeAmount={prizeAmount}
                        onStopSpinning={handleStopSpinning}
                      />
                    )}
                    {showPopup && <WinnerPopup winner={winner} prizeAmount={prizeAmount} onClose={handleClosePopup} />}
                  </>
                )}
                <ToastContainer />
              </>
            }
          />
          <Route path="/admin" element={<AdminDashboard />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
