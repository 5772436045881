import React, { useEffect, useState, useRef } from 'react';
import './Wheel.css';

const WHEEL_RADIUS = 300; // 2 times the original 150
const CENTRAL_CIRCLE_RADIUS = 20;
const TEXT_DISTANCE = 120;

function SpinWheel({ numbers, winner, prizeAmount, onStopSpinning }) {
  const [mustSpinAmount, setMustSpinAmount] = useState(false);
  const [mustSpinWinner, setMustSpinWinner] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(0);
  const [amountNumber, setAmountNumber] = useState(0);
  const [isSpinning, setIsSpinning] = useState(false);
  const [currentWheel, setCurrentWheel] = useState('amount');
  const [amountSpinComplete, setAmountSpinComplete] = useState(false);

  const allPrizeAmounts = [10, 20, 30, 40, 50, 75, 100, 200, 300, 400, 500, 1000];
  const maxAllowedPrize = 500;
  const [amountWheelLocked, setAmountWheelLocked] = useState(false);

  const amountWheelRef = useRef(null);
  const winnerWheelRef = useRef(null);

  useEffect(() => {
    if (winner !== null && prizeAmount !== null && !isSpinning) {
      const validPrizeAmount = Math.min(prizeAmount, maxAllowedPrize);
      setAmountNumber(allPrizeAmounts.indexOf(validPrizeAmount));
      setPrizeNumber(numbers.indexOf(winner));
      setIsSpinning(true);
      if (!amountWheelLocked) {
        console.log("Starting amount wheel spin");
        setMustSpinAmount(true);
      }
    }
  }, [winner, prizeAmount, numbers, allPrizeAmounts, isSpinning]);

  const handleStopSpinningAmount = () => {
    console.log("Amount wheel stopped spinning");
    setMustSpinAmount(false);
    setAmountSpinComplete(true);
    setAmountWheelLocked(true);
    setCurrentWheel('winner');
    setTimeout(() => {
      console.log("Starting winner wheel spin");
      setMustSpinWinner(true);
    }, 1000);
  };

  const handleStopSpinningWinner = () => {
    console.log("Winner wheel stopped spinning");
    setMustSpinWinner(false);
    setIsSpinning(false);
    onStopSpinning();
  };

  const getSegmentColor = (index) => {
    return index % 2 === 0 ? '#FFD700' : '#000000';
  };

  const spinWheel = (wheelRef, prizeNumber, duration, onComplete) => {
    const wheel = wheelRef.current;
    const totalRotation = 360 * 5 + (360 / wheel.childElementCount) * prizeNumber;
    
    wheel.style.transition = `transform ${duration}s cubic-bezier(0.25, 0.1, 0.25, 1)`;
    wheel.style.transform = `rotate(${-totalRotation}deg)`;

    setTimeout(() => {
      wheel.style.transition = 'none';
      wheel.style.transform = `rotate(${-(360 / wheel.childElementCount) * prizeNumber}deg)`;
      onComplete();
    }, duration * 1000);
  };

  useEffect(() => {
    if (mustSpinAmount) {
      spinWheel(amountWheelRef, amountNumber, 5, handleStopSpinningAmount);
    }
  }, [mustSpinAmount]);

  useEffect(() => {
    if (mustSpinWinner) {
      spinWheel(winnerWheelRef, prizeNumber, 5, handleStopSpinningWinner);
    }
  }, [mustSpinWinner]);

  const renderWheel = (data, wheelRef) => {
    const segmentAngle = 360 / data.length;
    return (
      <svg width={WHEEL_RADIUS * 2} height={WHEEL_RADIUS * 2} viewBox={`-${WHEEL_RADIUS} -${WHEEL_RADIUS} ${WHEEL_RADIUS * 2} ${WHEEL_RADIUS * 2}`}>
        <defs>
          <filter id="shadow" x="-20%" y="-20%" width="140%" height="140%">
            <feGaussianBlur in="SourceAlpha" stdDeviation="10" />
            <feOffset dx="0" dy="0" result="offsetblur" />
            <feFlood floodColor="#FFD700" />
            <feComposite in2="offsetblur" operator="in" />
            <feMerge>
              <feMergeNode />
              <feMergeNode in="SourceGraphic" />
            </feMerge>
          </filter>
        </defs>
        <g ref={wheelRef}>
          {data.map((item, index) => {
            const angle = index * segmentAngle;
            return (
              <g key={index} transform={`rotate(${angle})`}>
                <path
                  d={`M 0 0 L ${WHEEL_RADIUS} 0 A ${WHEEL_RADIUS} ${WHEEL_RADIUS} 0 0 1 ${WHEEL_RADIUS * Math.cos((segmentAngle * Math.PI) / 180)} ${WHEEL_RADIUS * Math.sin((segmentAngle * Math.PI) / 180)} Z`}
                  fill={getSegmentColor(index)}
                  stroke="#333"
                  strokeWidth="2"
                />
                <text
                  x={TEXT_DISTANCE}
                  y="0"
                  textAnchor="middle"
                  fill={index % 2 === 0 ? '#000000' : '#FFD700'}
                  fontSize="20"
                  fontWeight="bold"
                  transform={`rotate(${segmentAngle / 2}) ${index >= data.length / 2 ? 'rotate(180 ' + TEXT_DISTANCE + ' 0)' : ''}`}
                >
                  {item}
                </text>
              </g>
            );
          })}
          <circle r={CENTRAL_CIRCLE_RADIUS} fill="#FFD700" stroke="#333" strokeWidth="2" />
        </g>
        {/* Prominent arrow indicator */}
        <path
          d="M -30 -${WHEEL_RADIUS + 15} L 30 -${WHEEL_RADIUS + 15} L 0 -${WHEEL_RADIUS - 30} Z"
          fill="#FF0000"
          stroke="#333"
          strokeWidth="2"
          filter="url(#shadow)"
        />
      </svg>
    );
  };

  return (
    <div className="wheel-container my-2" style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '100vh',
      background: 'radial-gradient(circle, #2C3E50, #1A1A1A)',
      padding: '20px',
    }}>
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap',
        gap: '40px',
      }}>
        <div style={{
          position: 'relative',
          width: `${WHEEL_RADIUS * 2}px`,
          height: `${WHEEL_RADIUS * 2}px`,
        }}>
          {renderWheel(allPrizeAmounts.map(amount => `${amount} ብር`), amountWheelRef)}
          {currentWheel === 'amount' && isSpinning && (
            <div style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
              color: '#FFD700',
              padding: '20px',
              borderRadius: '10px',
              fontSize: '24px',
              fontWeight: 'bold',
              zIndex: 10,
              boxShadow: '0 0 20px rgba(255, 215, 0, 0.5)',
            }}>
              Spinning for Prize...
            </div>
          )}
          {amountSpinComplete && (
            <div style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
              color: '#FFD700',
              padding: '20px',
              borderRadius: '10px',
              fontSize: '24px',
              fontWeight: 'bold',
              zIndex: 10,
              boxShadow: '0 0 20px rgba(255, 215, 0, 0.5)',
            }}>
              Prize: {Math.min(prizeAmount, maxAllowedPrize)} ብር
            </div>
          )}
        </div>
        <div style={{
          position: 'relative',
          width: `${WHEEL_RADIUS * 2}px`,
          height: `${WHEEL_RADIUS * 2}px`,
        }}>
          {renderWheel(numbers, winnerWheelRef)}
          {currentWheel === 'winner' && isSpinning && (
            <div style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
              color: '#FFD700',
              padding: '20px',
              borderRadius: '10px',
              fontSize: '24px',
              fontWeight: 'bold',
              zIndex: 10,
              boxShadow: '0 0 20px rgba(255, 215, 0, 0.5)',
            }}>
              Spinning for Winner...
            </div>
          )}
        </div>
      </div>
      {!isSpinning && winner !== null && prizeAmount !== null && (
        <div style={{
          marginTop: '40px',
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
          color: '#FFD700',
          padding: '30px',
          borderRadius: '20px',
          fontSize: '32px',
          fontWeight: 'bold',
          textAlign: 'center',
          boxShadow: '0 0 30px rgba(255, 215, 0, 0.5)',
        }}>
          Winner: {winner}
          <br />
          Prize: {Math.min(prizeAmount, maxAllowedPrize)} ብር
        </div>
      )}
    </div>
  );
}

export default SpinWheel;